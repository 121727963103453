<template>
	<div class="layout">
		<s-dialog
			v-model="showWatchDemoDlg"
			class="scrollTarget"
			:transition-duration="300"
			:no-backdrop-dismiss="false"
			:auto-close="false"
			@dialog-show="cmdShowWatchDemoDialogShow"
		>
			<YouTubeVideo video-id="Gwm_Odue2gc" @close="cmdCloseRegisterDlg"></YouTubeVideo>
		</s-dialog>
		<router-view />
	</div>
</template>

<script>
	import { defineComponent, provide, ref, getCurrentInstance, onMounted, onBeforeMount, onUpdated, computed, nextTick } from 'vue'
	import { useFlowControlsStore } from './stores/flowControls'
	import { ready } from '@/plugins/dom-events'
	import { useCookies } from 'vue3-cookies'
	import { stanAnalytics } from '@/plugins/stan-analytics'
	import { AdsTracking } from '@/plugins/ads-tracking'
	import { SDialog } from '@/components/SDialog'
	import YouTubeVideo from '@/components/YouTubeVideo'

	export default defineComponent({
		name: 'App',
		components: {
			SDialog,
			YouTubeVideo,
		},
		setup() {
			const { cookies } = useCookies()
			const {
				proxy: { $emitter },
			} = getCurrentInstance()
			const showWatchDemoDlg = ref(false)

			const fcStore = useFlowControlsStore()

			const registerUrl = computed(() => {
				return `${process.env.VUE_APP_ADMIN_URL}/register?signupFlow=default-modal`
			})

			ready(async () => {
				fcStore.$patch({ fcpComplete: true })
			})

			function buildUrl(baseUrl, params) {
				// Filter out falsy values from the params object
				const filteredParams = Object.entries(params)
					.filter(([key, value]) => Boolean(value)) // Keep only truthy values
					.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) // Encode the params

				// Construct the query string
				const queryString = filteredParams.join('&')

				// Return the final URL
				return queryString ? `${baseUrl}?${queryString}` : baseUrl
			}

			const registerEvent = section => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section,
				}
				stanAnalytics('landing-registration-trigger', { meta: { username: 'guest' }, props })

				const urlString = window.location.href
				const url = new URL(urlString)
				const baseNewUrl = `${process.env.VUE_APP_ADMIN_URL}/register`
				const params = {
					ref: url.searchParams.get('ref'),
					utm_source: url.searchParams.get('utm_source'),
					utm_medium: url.searchParams.get('utm_medium'),
					utm_campaign: url.searchParams.get('utm_campaign'),
				}

				const newUrl = buildUrl(baseNewUrl, params)
				window.location.href = newUrl
				return false
			}

			const signIn = section => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section,
				}
				stanAnalytics('landing-signin-trigger', { meta: { username: 'guest' }, props })
				const urlString = window.location.href
				const url = new URL(urlString)
				const baseNewUrl = `${process.env.VUE_APP_ADMIN_URL}/`
				const params = {
					ref: url.searchParams.get('ref'),
					utm_source: url.searchParams.get('utm_source'),
					utm_medium: url.searchParams.get('utm_medium'),
					utm_campaign: url.searchParams.get('utm_campaign'),
				}

				const newUrl = buildUrl(baseNewUrl, params)
				window.location.href = newUrl
				return false
			}

			const registerByUrl = msg => {
				register(msg)
			}

			function cmdCloseRegisterDlg() {
				showWatchDemoDlg.value = false
			}

			const stanAnalyticsCall = async (name, section) => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section: section,
				}
				stanAnalytics(name, { meta: { username: 'guest' }, props })
			}

			function cmdWatchDemoVideo(section) {
				showWatchDemoDlg.value = true
				stanAnalyticsCall('landing-play-demo', section)
			}

			function cmdHomeVideoPlaying(section) {
				stanAnalyticsCall('landing-video-playing', '')
			}

			function cmdCloseWatchDemoVideo() {
				showWatchDemoDlg.value = false
			}

			const cmdShowWatchDemoDialogShow = async msg => {}

			const innerWidth = ref(0)
			const innerHeight = ref(0)

			function windowResize() {
				innerWidth.value = document.body.clientWidth
				innerHeight.value = document.body.clientHeight
			}

			// Added Status page
			const statusPage = document.createElement('script')
			statusPage.setAttribute('src', 'https://status.stan.store/embed/script.js')
			document.head.appendChild(statusPage)

			onMounted(() => {
				windowResize()
				$emitter.on('register', registerEvent)
				$emitter.on('sign.in', signIn)
				$emitter.on('home.video.playing', cmdHomeVideoPlaying)
				$emitter.on('watch.demo.video', cmdWatchDemoVideo)
				window.addEventListener('resize', () => windowResize())
				window.addEventListener(
					'message',
					event => {
						if (event.origin === process.env.VUE_APP_ADMIN_URL && event.data.user_registered) {
							window.location.href = process.env.VUE_APP_ADMIN_URL
						}
					},
					false
				)
				AdsTracking('Initialize')
			})
			onBeforeMount(() => {
				$emitter.off('register', registerEvent)
				$emitter.off('sign.in', signIn)
				$emitter.off('watch.demo.video', cmdWatchDemoVideo)
				window.removeEventListener('resize', () => windowResize())
			})

			return {
				showWatchDemoDlg,
				registerUrl,
				cmdCloseWatchDemoVideo,
				cmdShowWatchDemoDialogShow,
				cmdCloseRegisterDlg,
			}
		},
	})
</script>
<style lang="postcss">
	@import '@/assets/styles/bootstrap.min.css';
	@import '@/assets/styles/main.css';

	.layout {
		font-family: 'Grobek', sans-serif;
		background-size: cover;
	}
</style>
